import { Meric, Johnson, Generali, Quest, Harman, Adp, Audi, Bosch, Cgi, Ntuc, Ntt, Zurich } from "../images/index";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "request demo",
    link: "",
  },
];

const globalBrands = [Meric, Johnson, Generali, Quest, Harman, Adp, Audi, Bosch, Cgi, Ntuc, Ntt, Zurich];

const demoLists = [
  "How to create a well-defined and aligned goals culture",
  "How to drive performance behaviors",
  "How to ensure the achievement of goals with timely, personalized insights",
  "How to better engage your teams with continuous feedback",
  "How to empower people to perform, learn and grow",
];

export { breadCrumbItems, globalBrands, demoLists };
