import React from "react";
import Layout from "../../containers/global/layout";
import ContactForm from "../../components/HubspotForm";
import Container from "../../utils/Container";
import BreadCrumb from "../../containers/BreadCrumb";
import CarouselBrands from "../../components/CarouselBrands";
import { breadCrumbItems, globalBrands, demoLists } from "../../constants/request-demo";

const RequestDemo = () => {
  return (
    <Layout>
      <div className="relative md:bg-transparent">
        <div className="relative w-full mx-auto bg-[#FFDB00] mt-7">
          <p className="block pt-8 w-2/3 mx-auto md:hidden text-3xl font-normal text-center h-72">
            <span className=" font-extrabold">discover how entomo can </span> help you achieve your objectives
          </p>
          <div className="w-1/2 md:w-full mx-auto md:h-96 xl:h-[28rem]">
            <Container>
              <p className="text-3xl font-normal pt-12 hidden md:block w-[45%] lg:w-[35%] xl:w-1/4 leading-10">
                <span className="font-extrabold text-5xl">discover how entomo can </span>help you achieve your
                objectives ?
              </p>
            </Container>
          </div>
        </div>
        <div className="relative md:absolute -top-10 md:top-5 left-1/2 md:left-[60%] -translate-x-1/2 md:translate-x-0 w-[90%] sm:w-[70%] md:w-[35%]">
          <ContactForm
            formId="16efad2c-97bb-4ca7-95d6-8f77bf2e4569"
            portalId="1750400"
            containerId="hbspt-contact-form"
          />
        </div>
      </div>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ marginTop: "2%", marginBottom: "2%" }} />
        <div>
          <p className="mb-5 text-primary-blue-dark text-2xl font-extrabold">in this demo, you will learn:</p>
          <ul className="text-[#4d4d4d] text-base sm:text-sm xl:text-base w-full md:w-1/2 pl-5">
            {demoLists.map((demoList) => {
              return (
                <li key={demoList} className="list-disc mb-3">
                  {demoList}
                </li>
              );
            })}
            <p className=" mb-6">With entomo, we’re changing the way enterprises and teams approach performance.</p>
          </ul>
        </div>
      </Container>
      <CarouselBrands
        title={
          <div className="font-medium leading-loose text-xl md:text-3xl">
            <p>
              powering <span>30 million+</span> users,
            </p>
            <p>1.4 million+ businesses, trusted by global marquee brands</p>
          </div>
        }
        globalBrands={globalBrands}
      />
    </Layout>
  );
};

export default RequestDemo;
